<template>
    <div class="container">
        <div class="grid grid-cols-1 sm:grid-cols-1 mb-4">
            <center>
                <p class="RobotoBold text-2xl lg:text-3xl" style="color: #821740;">
                    Inscripción de Ahorro para Menores de Edad
                </p>
            </center>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-12 sm:gap-24 my-5">
            <div class="col-span-2 "></div>
            <div class="col-span-8">
                <center>
                    <button v-on:click="redirectTo('/saving-minors')">
                        <img src="/images/savings_for_minors/savings_for_minors.png"/>
                    </button>
                </center>
            </div>
            <div class="col-span-2 "></div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  name: 'SavingForMinors',
  components: {
  },
  methods: {
    redirectTo(route){
        window.location.href = route;
    }
  }
}
</script>