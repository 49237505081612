<template>
  <div>
    <div class=" justify-content-md-center" style="margin-top:50px;">
      <center>
        <p class="RobotoBold text-2xl lg:text-3xl" style="color: #821740;">
          Educación Financiera
        </p>
      </center>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-12 sm:gap-24 my-5">
      <div class="col-span-2 "></div>
      <div class="col-span-8">
        <Carousel :autoplay="5000" :wrap-around="true" @page-change="changeImageDefault()">
          
          <slide  :key="1">
            <button v-on:click="changeConsumo()">
              <img src="/images/banners/Consumo.png" v-if="consumoIsClicked == false" loading="lazy"/>
              <img src="/images/banners/Consumo_texto.png" v-if="consumoIsClicked == true" loading="lazy"/>
            </button>
          </slide>

          <slide :key="2">
            <button v-on:click="changePresupuesto()">
                <img src="/images/banners/Presupuesto-familiar.png" v-if="presupuestoIsClicked == false" loading="lazy"/>
                <img src="/images/banners/Presupuesto-familiar_texto.png" v-if="presupuestoIsClicked == true"  loading="lazy"/>
              </button>
          </slide>

          <slide :key="2">
            <button v-on:click="changeCooperativa()">
              <img src="/images/banners/Cooperativa.png" v-if="cooperativaIsClicked == false"  loading="lazy"/>
              <img src="/images/banners/Cooperativa_texto.png" v-if="cooperativaIsClicked == true" loading="lazy"/>
            </button>
          </slide>

          <slide :key="4">
            <button v-on:click="changePrincipiosCooperativos()">
              <img src="/images/banners/Principios-cooperativos.png" v-if="principiosCooperativosIsClicked == false" loading="lazy"/>
              <img src="/images/banners/Principios-cooperativos_texto.png" v-if="principiosCooperativosIsClicked == true" loading="lazy"/>
            </button>
          </slide>

          <slide :key="5">
            <button v-on:click="changeValoresCooperativos()">
              <img src="/images/banners/Valores-cooperativos.png"  v-if="valoresCooperativosIsClicked == false"  loading="lazy"/>
              <img src="/images/banners/Valores-cooperativos_texto.png"  v-if="valoresCooperativosIsClicked == true" loading="lazy"/>
            </button>
          </slide>

          <template #addons>
            <Pagination />
          </template>

        </Carousel>
      </div>
      <div class="col-span-2 "></div>
    </div>
    <!-- <div class="row justify-content-md-center">
      <div class="col-sm-2"></div>
      <div class="col-sm-8">
        <Carousel
          :autoplay="2000" :wrap-around="true"
          @page-change="changeImageDefault()"
        >
          

         <slide v-for="slide in 1" :key="slide">
            <button v-on:click="changeConsumo()">
              <img
                src="/images/banners/Consumo.png"
                v-if="consumoIsClicked == false"
              />
              <img
                src="/images/banners/Consumo_texto.png"
                v-if="consumoIsClicked == true"
              />
            </button>
          </slide>

          <slide v-for="slide in 1" :key="slide">
            <button v-on:click="changePresupuesto()">
              <img
                src="/images/banners/Presupuesto-familiar.png"
                v-if="presupuestoIsClicked == false"
              />
              <img
                src="/images/banners/Presupuesto-familiar_texto.png"
                v-if="presupuestoIsClicked == true"
              />
            </button>
          </slide>

          <slide v-for="slide in 1" :key="slide">
            <button v-on:click="changeCooperativa()">
              <img
                src="/images/banners/Cooperativa.png"
                v-if="cooperativaIsClicked == false"
              />
              <img
                src="/images/banners/Cooperativa_texto.png"
                v-if="cooperativaIsClicked == true"
              />
            </button>
          </slide>

          <slide v-for="slide in 1" :key="slide">
            <button v-on:click="changePrincipiosCooperativos()">
              <img
                src="/images/banners/Principios-cooperativos.png"
                v-if="principiosCooperativosIsClicked == false"
              />
              <img
                src="/images/banners/Principios-cooperativos_texto.png"
                v-if="principiosCooperativosIsClicked == true"
              />
            </button>
          </slide>

          <slide v-for="slide in 1" :key="slide">
            <button v-on:click="changeValoresCooperativos()">
              <img
                src="/images/banners/Valores-cooperativos.png"
                v-if="valoresCooperativosIsClicked == false"
              />
              <img
                src="/images/banners/Valores-cooperativos_texto.png"
                v-if="valoresCooperativosIsClicked == true"
              />
            </button>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
      <div class="col-sm-2"></div>
    </div> -->
    <!-- <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
    />
    <link
      href="../../node_modules/tailwindcss/dist/tailwind.min.css"
      rel="stylesheet"
    /> -->
  </div>
</template>


<script>
import VueCarousel from "vue-carousel";
import 'vue3-carousel/dist/carousel.css';

import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  data() {
    return {
      ahorroIsClicked: false,
      consumoIsClicked: false,
      presupuestoIsClicked: false,
      cooperativaIsClicked: false,
      valoresCooperativosIsClicked: false,
      principiosCooperativosIsClicked: false,
      slides: []
    };
  },
  name: "CarucelNoticias",
  props: {
    msg: String,
  },
  async beforeMount() {},
  async mounted() {},
  async created() {},
  methods: {
    changeAhorro() {
      this.cooperativaIsClicked = false;
      this.valoresCooperativosIsClicked = false;
      this.principiosCooperativosIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = false;
      this.ahorroIsClicked = !this.ahorroIsClicked;
    },
    changeConsumo() {
      this.cooperativaIsClicked = false;
      this.valoresCooperativosIsClicked = false;
      this.principiosCooperativosIsClicked = false;
      this.ahorroIsClicked = false;
      this.presupuestoIsClicked = false;
      this.consumoIsClicked = !this.consumoIsClicked;
    },
    changePresupuesto() {
      this.cooperativaIsClicked = false;
      this.valoresCooperativosIsClicked = false;
      this.principiosCooperativosIsClicked = false;
      this.ahorroIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = !this.presupuestoIsClicked;
    },

    changeCooperativa() {
      this.valoresCooperativosIsClicked = false;
      this.principiosCooperativosIsClicked = false;
      this.ahorroIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = false;
      this.cooperativaIsClicked = !this.cooperativaIsClicked;
    },
    changeValoresCooperativos() {
      this.cooperativaIsClicked = false;
      this.principiosCooperativosIsClicked = false;
      this.ahorroIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = false;
      this.valoresCooperativosIsClicked = !this.valoresCooperativosIsClicked;
    },
    changePrincipiosCooperativos() {
      this.cooperativaIsClicked = false;
      this.valoresCooperativosIsClicked = false;
      this.ahorroIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = false;
      this.principiosCooperativosIsClicked = !this
        .principiosCooperativosIsClicked;
    },
    changeImageDefault() {
      this.ahorroIsClicked = false;
      this.consumoIsClicked = false;
      this.presupuestoIsClicked = false;
      this.cooperativaIsClicked = false;
      this.valoresCooperativosIsClicked = false;
      this.principiosCooperativosIsClicked = false;
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    VueCarousel
  },
};
</script>

<style scoped>
@media (max-width: 654px) {
  .idDivCarusel1 {
    display: block;
  }
  .idDivCarusel2 {
    display: none;
  }
}
@media (min-width: 654px) {
  .idDivCarusel1 {
    display: none;
  }
  .idDivCarusel2 {
    display: block;
  }
}
</style>

