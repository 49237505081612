<template>
<div class="container mx-auto">
    <div class="flex-1 bg-gray-0" style="margin-top:50px;padding:0px 20px 0px 20px;">
        <div class="row">
            <center>
                <p class="text-2xl lg:text-3xl RobotoBold" style="color: #821740;">¿Qué necesitas para el trámite?</p>
            </center>
        </div>
    </div>
    <div class="flex-1 bg-gray-0" style="padding:0px 20px 0px 20px;">
        <div class="row">
          <br>
            <center>
                <p class="text-base RobotoRegular text-center" style="color: #726659;font-weight:300;">
                    La contratación de nuestros servicios es de rápido acceso y con gran seguridad<br>
                    de tus datos.
                </p>
            </center>
        </div>
    </div>
    <div class="bg-gray-0" style="margin-top:30px;">
        <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-4 px-10 md:px-28 py-5">
          <div class="md:col-span-1 col-span-3">
              <img class="img-responsive" src="@/assets/tramite1.svg" alt="tramite" width="100" height="100" style="margin:0 auto;" loading="lazy">
              <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-3xl RobotoRegular text-center mt-6 mb-2" style="color: #FEB72B;">Registrate en Línea</p>
              <p class="text-lg RobotoRegular text-center" style="color: #726659;">
                  Solo te llevará minutos</p>
          </div>
          <div class="md:col-span-1 col-span-3">
              <img class="img-responsive" src="@/assets/tramite2.svg" alt="tramite" width="100" height="100" style="margin:0 auto;" loading="lazy">
              <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-3xl RobotoRegular text-center mt-6 mb-2" style="color: #FEB72B;">Recibe nuestra llamada</p>
              <p class="text-lg RobotoRegular text-center" style="color: #726659;">
                    De ser necesario un asesor te contactará y
                    validará algunos datos, ten a
                    la mano una identificación
                    oficial y un comprobante de
                    domicilio.</p>
          </div>
          <div class="md:col-span-1 col-span-3">
              <img class="img-responsive" src="@/assets/tramite3.svg" alt="tramite" width="100" height="100" style="margin:0 auto;" loading="lazy">
              <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-3xl RobotoRegular text-center mt-6 mb-2" style="color: #FEB72B;">Finaliza el proceso</p>
              <p class="text-lg RobotoRegular text-center" style="color: #726659;">
                  Una vez validados tus datos
                  serás un nuevo Socio
                  FONCABSA.</p>
          </div>
      </div>
    </div>
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> --></div>
</template>





<script>
export default {
  name: 'Tramite',
  props: {
    msg: String
  }
}
</script>