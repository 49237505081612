import { get, post } from 'axios'

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function getFactors () {
    const resource = `/${business}/factor/get_savings_actives_factors`
    const API_URL = `${host}${resource}`
    
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {}
    
    return get(API_URL,  {params, config} )
}


export {
    getFactors
}