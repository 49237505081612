<template>
<div class="container mx-auto py-10">
    <div class="flex-1 bg-gray-0 px-0 py-1 m-1 mt-16">
        <div class="row">
            <center>
                <p class="RobotoBold text-2xl lg:text-3xl" style="color: #821740;">Comparación</p>
            </center>
        </div>
    </div>
    <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
        <div class="row">
            <center>
                <p class="text-sm lg:text-2xl RobotoRegular" style="color: #726659;">
                    Compara y evalúa, somos la mejor opción.
                </p>
            </center>
        </div>
    </div>
    <div class="flex-1 px-0 mt-10">
        <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
            <div class="row">
                <center>
                    <div class="panel panel-default RobotoRegular" id="panel" style="background-color: #821740;margin-bottom:55px;width:280px">
                        <center><div class="text-white font-semibold text-3xl pt-4">FONCABSA</div></center>
                        <center class="text-white mt-1 text-xs">Sistema Financiero Colaborativo</center>
                        <br>
                        <div class="grid grid-cols-8 px-4">
                                <div class="col-span-1">
                                    <img src="@/assets/palomita.svg" class="mt-1" height="24" width="24" loading="lazy"/>    
                                </div>
                                <div class="col-span-7 ml-2">
                                    <h6 class="text-base text-white font-light text-left">
                                    Planes de ahorro que<br>
                                    contribuyen a un proceso<br> de
                                    retiro.</h6>
                                </div>
                            <br>
                        </div>
                        <div class="grid grid-cols-8 px-4">
                            <div class="col-span-1">
                                <img src="@/assets/palomita.svg" class="mt-1" height="24" width="24" loading="lazy"/>   
                            </div>       
                            <div class="col-span-7 ml-2">
                                <h6 class="text-base text-white font-light text-left">
                                Acceso a créditos en <br>corto
                                plazo.
                                </h6>
                            </div>
                            <br>
                        </div>
                        <div class="grid grid-cols-8 px-4">
                            <div class="col-span-1">
                                <img src="@/assets/palomita.svg" class="mt-1" height="24" width="24" loading="lazy"/>    
                            </div>
                            <div class="col-span-7 ml-2">
                                <h6 class="text-base text-white font-light text-left">
                                    Tus ahorros y descuentos por
                                    créditos otorgados serán vía
                                    nómina si existe convenio o con cargo a tarjeta.
                                </h6> 
                            </div>
                            <br>
                        </div>
                    </div>
                </center>
            </div>
        </div>
    </div>
    <!--
    <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
        <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lx:grid-cols-3">
            <div class="row">
                <center>
                         <div class="panel panel-default" id="panel" style="background-color: rgb(221, 141, 181); margin-bottom:55px;width:280px">
                        <center><h3 style="color:#ffffff; font-weight: bold;">FONSNTE</h3></center>
                        <center style="color:#ffffff; font-size: 8px; margin-top:-12px;">Sistema Financiero Colaborativo</center>
                        <br>
                        <div class="row">
                                <img src="@/assets/palomita.svg" class="col-span-2" class="mt-1" height="24" width="24"/>    
                                <div class="col-span-7 ml-2">
                                    <h6 class="text-white font-light text-left">
                                    Planes de ahorro que<br>
                                    contribuyen a un proceso<br> de
                                    retiro.</h6>
                                </div>
                            <br>
                        </div>
                        <div class="row">
                            <img src="@/assets/palomita.svg" class="col-span-2" style="margin-left:-245px; margin-top:-2px;" class="mt-1" height="24" width="24"/>          
                            <div class="col-span-7 ml-2">
                                <h6 class="text-white font-light text-left">
                                Acceso a créditos en <br>corto
                                plazo.
                                </h6>
                            </div>
                            <br>
                        </div>
                        <div class="row">
                            <img src="@/assets/palomita.svg" class="col-span-2" style="margin-left:-245px; margin-top:2px;" class="mt-1" height="24" width="24"/>    
                            <div class="col-span-7 ml-2">
                                <h6 class="text-white font-light text-left">
                                    Tus ahorros y descuentos por<br>
                                    créditos otorgados serán vía<br>
                                    nómina
                                </h6>
                            </div>
                            <br>
                        </div>
                    </div>
                </center>
            </div>
            <div class="row">
                <center>
                        <div class="panel panel-default" id="panel" style="margin-bottom:55px;width:280px">
                        <br>
                        <center><h4 style="color:#6E6E6E; font-weight: bold;">Otras instituciones</h4></center>
                        <br>
                        <div class="row"> 
                           <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div style="margin-left:55px;height:50px">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                            </div>
                            <br>
                        </div>
                        <div class="row">
                           <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div class="col-span-7 ml-2">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                            </div>
                            <br>
                        </div>
                       <div class="row">
                          <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div class="col-span-7 ml-2">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                            </div>
                            <br>
                        </div>
                    </div>
                </center>
            </div>
            <div class="row">
                <center>
                        <div class="panel panel-default" id="panel" style="margin-bottom:55px;width:280px">
                        <br>
                        <center><h4 style="color:#6E6E6E; font-weight: bold;">Otras instituciones</h4></center>
                        <br>
                        <div class="row"> 
                            <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div style="margin-left:55px;height:50px">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                                </div>
                            <br>
                        </div>
                        <div class="row">
                            <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div class="col-span-7 ml-2">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                                </div>
                            <br>
                        </div>
                       <div class="row">
                           <img src="@/assets/tacha.svg" class="col-span-2" style="margin-left:-235px; margin-top:6px;" class="mt-1" height="24" width="24"/>    
                            <div class="col-span-7 ml-2">
                                <h6 style="margin-top:-18px;color:#6E6E6E; margin-left:-7px;">
                                    Lorem ipsum dolor sit amet,<br>
                                    consectetur adipiscing</h6>
                            </div>
                            <br>
                        </div>
                    </div>
                </center>
            </div>
        </div>
    </div>
    -->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>



<script>
export default {
  name: 'Comparacion',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
</style>