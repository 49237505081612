<template>
  <div>
    <div class="flex-1 bg-gray-0 py-12" style="background-color:#f2f6ff;margin:0 auto">
    <div class="md:grid md:grid-cols-2 grid grid-cols-1 gap-4" style="font-family:Roboto;">
      <div class="flex" style="justify-content:center">
        <div class="md:flex-shrink-0">
          <img class="rounded-lg md:w-56" src="@/assets/mision.svg" alt="mision" style="height:100px;width:160px" loading="lazy">
        </div>
        <div class="mt-4 md:mt-0 md:ml-6">
          <p class="text-3xl RobotoBold" style="color: #FEB72B;">Misión</p>
          <p class="text-lg lg:text-xl RobotoLight" style="color: #726659;">
              Promover la inclusión de la<br>
              población a servicios financieros<br>
              diversificados y formales, así<br>
              como la promoción de la cultura<br>
              Financieras para contribuir en el<br>
              desarrollo y bienestar económico<br>
              de las familias mexicanas.</p>
        </div>
      </div>

      <div class="flex" style="justify-content:center">
        <div class="md:flex-shrink-0">
          <img class="rounded-lg md:w-56" src="@/assets/vision.svg" alt="vision" style="height:100px;width:160px" loading="lazy">
        </div>
        <div class="mt-4 md:mt-0 md:ml-6">
          <p class="text-3xl RobotoBold" style="color: #FEB72B;">Visión</p>
          <p class="text-lg lg:text-xl RobotoLight" style="color: #726659;">
              Ser una Sociedad Cooperativa<br>
              profesional y competitiva con una<br>
              infraestructura financiera sólida,<br>
              basada en servicios rentables.</p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'MisionVision',
  props: {
    msg: String
  }
}
</script>