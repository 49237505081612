<template>
    <div class="sm:px-20 py-5 my-24" >
        <div class="flex-1 bg-gray-0 m-1">
            <div class="row">
                <center>
                    <p class="text-3xl RobotoBold" style="color: #821740;">Beneficios</p>
                </center>
            </div>
        </div>
        <div class="bg-gray-1 m-0" style="display:flex;justify-content:center;margin-top:30px;padding:0px 60px 0px 60px;">
            <div class="bg-gray-000 h-34 grid grid-cols-1 md:grid md:grid-cols-3 gap-10">
                <div>
                    
                        <img src="@/assets/beneficio1.svg" width="100" height="100" alt="beneficio" class="img-responsive mx-auto mt-7 mb-4" loading="lazy" />
                        <p class="text-lg text-center RobotoRegular" style="color: #726659;">Te prestamos al estar inscrito</p>
                    
                </div>
                <div>
                    
                        <img src="@/assets/beneficio2.svg" width="100" height="100" alt="beneficio" class="img-responsive mx-auto mt-7 mb-4" loading="lazy" />
                        <p class="text-lg text-center RobotoRegular" style="color: #726659;">Tasas fijas, las más competitivas <br>del mercado.
                                                    </p>
                    
                </div>
                <div>
                    
                        <img src="@/assets/beneficio3.svg" width="100" height="100" alt="beneficio" class="img-responsive mx-auto mt-7 mb-4" loading="lazy" />
                        <p class="text-lg text-center RobotoRegular" style="color: #726659;">Sin costos ocultos</p>
                    
                </div>
                <div>
                    
                        <img src="@/assets/beneficio4.svg" width="100" height="100" alt="beneficio" class="img-responsive mx-auto mt-7 mb-4" loading="lazy" />
                        <p class="text-lg text-center RobotoRegular" style="color: #726659;">
                            Opciones de pago vía nómina si existe convenio o con cargo a tarjeta
                        </p>
                    
                </div>
                <div>
                    
                        <img src="@/assets/beneficio5.svg" width="100" height="100" alt="beneficio" class="img-responsive mx-auto mt-7 mb-4" loading="lazy" />
                        <p class="text-lg text-center RobotoRegular" style="color: #726659;">Tu crédito lo puedes pagar<br>
                                                        en diferentes plazos</p>
                    
                </div>
            </div>
        </div>
    </div>
</template>




<script>
export default {
  name: 'Beneficios',
  props: {
    msg: String
  }
}
</script>