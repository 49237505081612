<template>
<div class="px-5 md:px-20" style="background-color:#f2f6ff;">
    <div class="flex-1 px-0 py-1 m-1 mt-12">
        <div class="row">
            <center>
                <p class="text-2xl lg:text-3xl RobotoBold mt-10" style="color: #821740;">¿Cómo funciona?</p>
            </center>
        </div>
    </div>
    <div class="flex-1 px-0 py-1 m-1">
        <div class="row">
            <center>
                <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-3xl xl:text-4xl" style="color: #6E6E6E;"></p>
            </center>
        </div>
    </div>
    <div class="md:grid md:grid-cols-2 lg:grid-cols-4 grid grid-cols-1 gap-0 md:gap-4 mt-10 sm:px-20">
        <div class="">
            <div class="panel panel-default shadow-md" id="panel" style="margin-bottom:55px;height:80%;padding:20px;background-color:white">
                <center><img src="@/assets/money-in-hand.png" loading="lazy"></center>
                <center>
                    <div style="color:#726659" class="RobotoRegular text-lg md:text-xl mt-5 px-2">Elige el importe que deseas ahorrar.</div>
                </center>
            </div>
        </div>
        <!-- ... -->
        <div class="">
            <div class="panel panel-default shadow-md" id="panel" style="margin-bottom:55px;height:80%;padding:20px;background-color:white">
                <!-- <center><img src="../assets/money-in-hand.png" class="img-responsive" height="110px" width="110px"></center> -->
                <center><img src="@/assets/people.png" loading="lazy"></center>
                <center>
                    <div style="color:#726659" class="RobotoRegular text-lg md:text-xl mt-5 px-2">Designa a tus beneficiarios.</div>
                </center>
            </div>
        </div>
        <div class="">
            <div class="panel panel-default shadow-md" id="panel" style="margin-bottom:55px;height:80%;padding:20px;background-color:white">
                <!-- <center><img src="../assets/comofunciona3.svg" class="img-responsive" height="110px" width="110px"></center> -->
                <center><img src="@/assets/money-card.png" loading="lazy"></center>
                <center>
                    <div style="color:#726659" class="RobotoRegular text-lg md:text-xl mt-5 px-2">
                        Tu aportación al ahorro podrás realizarla con cargo a tarjeta o descuento vía nómina si existe convenio
                        <br></div>
                </center>
                <br>
            </div>
        </div>
        <div class="">
            <div class="panel panel-default shadow-md" id="panel" style="margin-bottom:55px;height:80%;padding:20px;background-color:white">
                <!-- <center><img src="../assets/comofunciona4.svg" class="img-responsive" height="110px" width="110px"></center> -->
                <center><img src="@/assets/money-stock.png" loading="lazy"></center>
                <center>
                    <div style="color:#726659" class="RobotoRegular text-lg md:text-xl mt-5 px-2">Tu ahorro está protegido</div>
                </center>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ComoFunciona',
    props: {
        msg: String
    }
}
</script>

<style scoped>
#panel {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.panel-default {
    border: 1px solid rgb(221, 221, 221);
}
</style>
