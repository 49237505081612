import SavingsYield from "@/classes/SavingsYield";
import { getFactors } from "@/api/saving_interest_factor";

export default class SavingsYieldCalculator {
    
    constructor(){
    }

    async execute(amount, years) {
        var proyection = []
        var savingsYieldObject = new SavingsYield()
        var listFactors = await SavingsYieldCalculator.getFactors()

        var monthsToCalculate = savingsYieldObject.getCalculateNumberAccordingToTerm(years, "meses");
        var actualMonth = new Date(new Date().getFullYear(), new Date().getMonth()+1, 1);
        var annualizedFactor = savingsYieldObject.getAnnualizedFactor(listFactors)
        var interest = 0
        var totalSavings = 0

        var firstDaysOfFortnight = 14

        var amountToCalculate = 0

        for (let i = 1; i < monthsToCalculate+1; i++) {
            var savingsAccumulated = 0
            var savingsAverage = 0
            var daysOnMonth = new Date(actualMonth.getFullYear(), actualMonth.getMonth()+1, 0).getDate();
            var daysToCalculate = SavingsYieldCalculator.substractNumberOfDays(daysOnMonth, 15)
            
            if(i==1){
                amountToCalculate += Number(amount)
                
                savingsAccumulated += SavingsYieldCalculator.calculateAccumulatedSavings(daysToCalculate, amount)
                savingsAccumulated += Number(amount)
                if(daysOnMonth <= 30){
                    savingsAccumulated += Number(amount)
                }
                totalSavings += Number(amount)

                savingsAverage = SavingsYieldCalculator.calculateSavingsAverage(daysToCalculate, savingsAccumulated)
            }else{
                savingsAccumulated += firstDaysOfFortnight * (interest + amountToCalculate)
                amountToCalculate += Number(amount)
                

                if(daysOnMonth <= 30){
                    savingsAccumulated += (daysToCalculate+1) * (interest + amountToCalculate)
                    savingsAccumulated += Number(amount)
                }else{
                    savingsAccumulated += (daysToCalculate+1) * (interest + amountToCalculate)
                    savingsAccumulated += Number(amount)
                    savingsAccumulated += Number(amount)
                }
                
                totalSavings += Number(amount)

                savingsAverage = SavingsYieldCalculator.calculateSavingsAverage(daysOnMonth, savingsAccumulated)
            }

            amountToCalculate += Number(amount)
            totalSavings += Number(amount)

            var calculateFactorMonth = savingsYieldObject.getFactor(listFactors, savingsAverage)
            var cumulativeFactorCalculationAmount = savingsAccumulated * calculateFactorMonth
            var monthlyInterest = cumulativeFactorCalculationAmount / annualizedFactor;
            totalSavings += monthlyInterest

            //proyeccion
            interest += monthlyInterest

            proyection.push({date:actualMonth.toDateString(), factor: calculateFactorMonth, monthlyInterest:monthlyInterest, accumulateSaving: savingsAccumulated, daysOnMonth:daysOnMonth, totalSaving:totalSavings})
            actualMonth.setMonth(actualMonth.getMonth()+1)
        }
        
        return proyection
    }

    static substractNumberOfDays(daysOfMonth, days){
        return daysOfMonth - days
    }

    static calculateAccumulatedSavings(daysOfMonth, amount){
        return daysOfMonth * Number(amount);
    }

    static calculateSavingsAverage(totalDaysOfMonth, accumulateSavings){
        return accumulateSavings / totalDaysOfMonth
    }

    // async execute(amount, years) {
    //     var proyection = []
    //     var savingsYieldObject = new SavingsYield()
    //     var listFactors = await SavingsYieldCalculator.getFactors()

    //     var months = savingsYieldObject.getCalculateNumberAccordingToTerm(years, "meses");
    //     var date = new Date(new Date().getFullYear(), new Date().getMonth()+1, 1);       
    //     var annualizedFactor = savingsYieldObject.getAnnualizedFactor(listFactors)

    //     var accumulateSaving = 0
    //     var calculateFactorMonth = 0

    //     var savingAccumulateWithoutInterest = 0
    //     var interest = 0

    //     for (let i = 1; i < months+1; i++) {
    //         debugger

    //         var daysOnMonth = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();     
    //         var saving = accumulateSaving
            
    //         var iSfisrtMonth = i==1

    //         var fistDaysOfMonth = 14;
    //         var lastDaysOfMonth = daysOnMonth - 15;

    //         if(iSfisrtMonth){
    //             saving += lastDaysOfMonth * (interest + Number(amount));
    //         }else{
    //             saving += fistDaysOfMonth * (interest + (Number(amount) * (i-1)))
    //             saving += lastDaysOfMonth * (interest + (Number(amount)* i))
    //         }
            

    //         //saving = savingsYieldObject.getAccumulatedSavingsAccordingToTerm(iSfisrtMonth, amount, daysOnMonth, saving, "meses")
    //         //saving = saving + (Number(amount)*(i+i))

    //         calculateFactorMonth = savingsYieldObject.getFactor(listFactors, ((interest + Number(amount))*i))
    //         var cumulativeFactorCalculationAmount = saving * calculateFactorMonth
    //         var monthlyInterest = cumulativeFactorCalculationAmount / annualizedFactor;
            
    //         //monthlyInterest = Math.round(monthlyInterest*100)/100;
    //         interest += monthlyInterest

    //         if (i == 1) {
    //             accumulateSaving += monthlyInterest +  Number(amount)
    //             savingAccumulateWithoutInterest += Number(amount)
    //         }else{
    //             accumulateSaving += monthlyInterest +  Number(amount)
    //             savingAccumulateWithoutInterest += Number(amount)
    //         }

    //         var totalSaving = accumulateSaving
    //         //totalSaving = Math.round(totalSaving*100)/100;
    //         saving = Math.round(saving*100)/100;
    //         proyection.push({date:date.toDateString(), factor: calculateFactorMonth, monthlyInterest:monthlyInterest, accumulateSaving: savingAccumulateWithoutInterest, daysOnMonth:daysOnMonth, accumulate:saving, totalSaving:totalSaving})
    //         date.setMonth(date.getMonth()+1)
    //     }

    //     return proyection
    // }

    static getFactors(){
        return getFactors().then((response) => {
            return response.data.response.data;
        })
        .catch((error) => {
            throw error
        })
    }
}
