<template>
<div class="sm:px-20 py-5">
    <div class="flex-1 px-0 py-1 m-5" style="margin-top:50px;">
        <div class="row">
            <center>
                <p class="text-3xl RobotoBold mb-10" style="color: #821740;">Valores</p>
            </center>
        </div>
    </div>
    <div class="">
        <div class="flex bg-gray-000 h-34 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 lx:grid-cols-5 gap-4 lg:gap-2">
                <div class="row">
                    <center>
                        <img src="@/assets/confianza.svg" alt="confianza" class="img-responsive" width="100" height="130" loading="lazy"/>
                        <p class="text-lg lg:text-2xl mt-6 RobotoRegular text-center" style="color: #726659;">Confianza</p>
                    </center>
                </div>
                <div class="row">
                    <center>
                        <img src="@/assets/compromiso.svg" alt="compromiso" class="img-responsive" width="100" height="130" loading="lazy"/>
                        <p class="text-lg lg:text-2xl mt-6 RobotoRegular text-center" style="color: #726659;">Compromiso</p>
                    </center>
                </div>
                <div class="row">
                    <center>
                        <img src="@/assets/transparencia.svg" alt="transparencia" class="img-responsive" width="100" height="130" loading="lazy"/>
                        <p class="text-lg lg:text-2xl mt-6 RobotoRegular text-center" style="color: #726659;">Transparencia</p>
                    </center>
                </div>
                <div class="row">
                    <center>
                        <img src="@/assets/responsabilidad.svg" alt="responsabilidad" class="img-responsive" width="100" height="130" loading="lazy"/>
                        <p class="text-lg lg:text-2xl mt-6 RobotoRegular text-center" style="color: #726659;">Responsabilidad</p>
                    </center>
                </div>
                <div class="row">
                    <center>
                        <img src="@/assets/honestidad.svg" alt="honestidad" class="img-responsive" width="100" height="130" loading="lazy"/>
                        <p class="text-lg lg:text-2xl mt-6 RobotoRegular text-center" style="color: #726659;">Honestidad</p>
                    </center>
                </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Valores',
  props: {
    msg: String
  }
}
</script>