<template>
<div class="container mx-auto">
    <div class="flex-1 bg-gray-0 px-0 py-1 mx-1 mt-12 mb-16">
        <div class="row">
            <center>
                <p class="RobotoBold text-2xl lg:text-3xl" style="color: rgb(130, 23, 64);">Trabajamos juntos para brindarte un mejor servicio</p>
            </center>
        </div>
    </div>
    <div class="flex-1 bg-gray-1 mb-16" style="margin-top:30px;">
        <div class="flex bg-gray-000 h-34 grid grid-cols-º sm:grid-cols-1 md:grid-cols-1 lx:grid-cols-1">
            <div class="row" >
                <center>
                    <div class="flex bg-gray-000 h-34 grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lx:grid-cols-4">
                        <div class="row">
                            <center>
                                <a href="https://www.burodecredito.com.mx/" target="_blank">
                                <img class="w-16 h-16 md:w-28 md:h-28 lg:w-24 lg:h-24" src="@/assets/buro.webp" loading="lazy"></a>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                                <a href="https://www.gob.mx/cnbv/acciones-y-programas/preguntas-frecuentes-socap" target="_blank">
                                <img class="w-16 h-24 md:w-28 md:h-36 lg:w-24" src="@/assets/cnbv_img.webp" loading="lazy"></a>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                                <a href="https://www.condusef.gob.mx/?p=mapa-socap&ide=1" target="_blank">
                                <img class="w-16 h-14 md:w-28 md:h-24 lg:w-36 lg:h-24" src="@/assets/condusef_img.webp" loading="lazy"></a>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                                <a href="http://focoop.com.mx/WebSite16/" target="_blank">
                                <img class="w-16 h-14 md:w-28 md:h-24 lg:w-48 lg:h-28" src="@/assets/FOCOOP.webp" loading="lazy"></a>
                            </center>
                        </div>
                    </div>
                </center>
                <br>
            </div>
        </div>
    </div>
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> --></div>
</template>

<script>
export default {
  name: 'Colaboradores',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
</style>