<template>
  <div class="container mx-auto">
    <div class="flex-1 m-1 px-5 lg:px-36 my-12">
      <p
        class="text-3xl RobotoBold mb-5"
        style="color: #821740;"
      >
        ¿Qué es FONCABSA?
      </p>
      <p
        class="text-base md:text-xl mt-3 text-justify RobotoLight"
        style="color: #726659;"
      >
        FONCABSA es una sociedad cooperativa de ahorro y préstamo, que ofrece
        beneficios a corto, mediano y largo plazo. En FONCABSA descubrirás a
        través de distintas soluciones Financieras tales como cuentas de ahorro,
        préstamos y planes de protección familiar para afrontar emergencias que
        te permitan construir tu patrimonio.
      </p>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  name: "QuienesSomos",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.contentWhoWeAre {
  align-items: flex-start;
  font-family: Roboto;
  padding: 0px 160px 0px 160px;
}

@media (max-width: 1279px) {
  .contentWhoWeAre {
    padding: 0px 120px 0px 120px;
  }
}

@media (max-width: 1133px) {
  .contentWhoWeAre {
    padding: 0px 100px 0px 100px;
  }
}

@media (max-width: 768px) {
  .contentWhoWeAre {
    padding: 0px 60px 0px 60px;
  }
}
</style>
