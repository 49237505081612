<template>
<div class="container">
    <div class="">
        <div class="containerr">
            <img src="@/assets/SINAPTISSSTE/seguro-suficiente.png" alt="seguro-suficiente" class="w-screen bg-image-slide" loading="lazy">
            <div class="centered-item my-12">
                <div class="grid grid-cols-3">
                    <div class="mx-auto">
                        <img src="@/assets/SINAPTISSSTE/logo-SINAPTISSSTE.png" alt="SINAPTISSSTE" class="image-slide-size" width="340" loading="lazy">
                    </div>

                    <div class="col-span-2 mx-auto py-16 text-right">
                        <p class="text-md md:text-3xl lg:text-4xl mb-3 text-white font-light">Si eres parte de <b>SINAPTISSSTE</b> ya puedes formar parte de <b>FONCABSA</b> de forma rápida</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    useRouter
} from "vue-router";
export default {
    name: 'PanelInformativo1',
    props: {
        msg: String
    },
    setup() {
        const router = useRouter();
        return {
            router
        }
    },
    methods: {
        reedirect(path) {
            this.router.push({
                path: path
            });
            // window.location.href = path
        }
    }
}
</script>

<style scoped>
#texto {
    text-decoration-color: white;
}

#button_menu {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: white;
    font-size: 17px;
}

#button_comenzar_ahorro {
    border-radius: 5px !important;
    height: 40px;
    border: 1px solid #FEB72B;
    background-color: #FEB72B;
    padding-top: 0%;
    color: #fff;
    font-family: Roboto !important;
    font-size: 15px !important;
}

h1 {
    font-size: 5vw;
}

h2 {
    font-size: 5vw;
}

h3 {
    font-size: 5vw;
}

h4 {
    font-size: 5vw;
}

h5 {
    font-size: 5vw;
}
</style>

<style>
.centered-item {
    position: absolute;
    top: 45%;
    left: 23%;
    transform: translate(-20%, -50%);
    font-family: Roboto;
    text-align: right;
    width: 90%;
}

.containerr {
    position: relative;
    text-align: center;
    color: white;
}

.container {
    display: inline !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.centered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-20%, -50%);
    font-family: Roboto;
    text-align: right;
}

.mainTitle {
    color: #821740;
}

.subTitle {
    color: #726659;
}

/* 2xl */
@media (min-width: 1536px) {}

/* xl */
@media (max-width: 1280px) {}

/* lg */
@media (max-width: 1024px) {}

/* md */
@media (max-width: 768px) {}

@media only screen and (max-width: 640px) {

    .centered {
        top: 50%;
        left: 55% !important;
    }

    #button_comenzar_ahorro {
        margin-top: 20px;
    }
}

/* sm */
@media only screen and (max-width: 500px) {

    .centered {
        top: 50%;
        left: 45% !important;
    }

    #button_comenzar_ahorro {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .centered {
        top: 50% !important;
        left: 48% !important;
    }
}

.bg-image-slide {
    height: 655px;
}
</style>
