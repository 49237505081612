<template>
<div class="sm:px-20 my-24">
    <div class="flex-1 px-0 py-1 m-1 mb-5">
        <div class="row">
            <center>
                <p class="text-3xl RobotoBold" style="color: #821740;">Ventajas de ahorrar con FONCABSA</p>
            </center>
        </div>
    </div>
    <div class="flex-1 m-1 p-7 lg:p-14">
        <div class="grid grid-cols-1 sm:grid-cols-12 md:grid-cols-12 lx:grid-cols-12">
            <div class="col-span-12 sm:col-span-5" style="background-color: #FFFFFF;">
                <center>
                    <img src="@/assets/hombre.png" alt="hombre" class="img-responsive" loading="lazy"/>
                </center>
            </div>

            <div class=" col-span-12 sm:col-span-7 mt-20 sm:mt-0 ">
                <div class="row">
                    <div>
                        <img style="margin-left:0px" class="img-responsive" src="@/assets/seguridad.svg" alt="seguridad" width="100" height="100" loading="lazy">
                    </div>
                    <div class="flex-1" style="margin-left:130px; margin-top:-110px">
                        <p class="text-3xl RobotoRegular mt-4" style="color: #FEB72B;">
                            Seguridad</p>
                        <p class="text-sm lg:text-xl RobotoLight" style="color: #726659;">
                            Somos una sociedad cooperativa de ahorro y préstamo con nivel de operación básico evaluada y clasificada por su nivel de capitalización por el Fondo de Supervisión Auxiliar de Sociedad Cooperativas de Ahorro y Préstamo y de Protección a sus Ahorradores (FOCOOP).</p>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <img class="img-responsive" src="@/assets/inclusion.svg" alt="inclusion" width="100" height="100" style="margin-top:40px; margin-left:0px" loading="lazy">
                    </div>
                    <div class="flex-1" style="margin-left:130px; margin-top:-110px">
                        <p class="text-3xl RobotoRegular mt-4" style="color: #FEB72B;">
                            Inclusión financiera y cooperativa</p>
                        <p class="text-sm lg:text-xl RobotoLight" style="color: #726659;">
                            Somos una empresa que contribuye a la
                            inclusión financiera y cooperativa,
                            fortaleciendo así el crecimiento de
                            México.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1 px-0 py-1 m-1 mt-5" style="display:flex;justify-content:center;">
        <a href="/registro"><input type="button" value="Comienza tu ahorro" href="/registro" class="text-white font-semibold" id="button_comenzar_ahorro"></a>
    <br>
    <br>
    </div>
</div>
</template>

<script>
export default {
  name: 'Ventajas',
  props: {
    msg: String
  }
}
</script>

<style scoped>
#button_comenzar_ahorro{
        border-radius: 5px;
        background-color: #FEB72B;
        width: 250px;
        height: 50px;
        padding-top: 0%;
    }
</style>