<template>
    <div>
        <div style="background-color:#f2f6ff;" class="pb-20">
            <div class="flex-1 bg-gray-0 px-0 py-1 m-1" style="margin-top:50px;padding-top:30px;">
                <div class="w-full">
                    <center>
                        <p class="text-3xl mt-12 RobotoBold" style="color: #821740;">Simulador de ahorro</p>
                    </center>
                </div>
            </div>
            <div class="flex-1 bg-gray-0 px-0 py-1 m-1" style="padding:10px 60px 10px 60px;">
                <br>
                <div class="w-full">
                    <center>
                    <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-2xl xl:text-xl RobotoRegular" style="color: #726659;">¡Comienza a ahorrar! Prueba nuestro simulador y comienza a crear tu plan de ahorro, es muy fácil de usar.</p>
                    </center>
                </div>
            </div>
            <div class="flex-1 bg-gray-0 m-1" style="padding:10px 60px 10px 60px;">
                <div class="w-full">
                    <center>
                    <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-2xl xl:text-xl RobotoRegular" style="color: #726659;">Selecciona cuanto deseas ahorrar, el ahorro mínimo quincenal es de $50.00 y el ahorro máximo de $500.00 <br>(solo se puede ahorrar en múltiplos de $50.00).</p>
                    </center>
                </div>
                <br>
            </div>
            <div class="flex-1 bg-gray-0  py-1 m-1 sm:px-20">
                <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lx:grid-cols-2 justify-items-center ">
                    <div class="w-full shadow-sm rounded-md border border-slate-200 px-10 py-5 sm:w-3/4" style="background-color:white;">
                        <div>
                            <p style="margin-top:30px;text-align:left;font-family:Roboto;color:#726659;font-weight:700;" class="text-xl">Importe quincenal a ahorrar</p>
                            <p style="margin-bottom:-25px;text-align:left;font-family:Roboto;color:#726659;font-weight:500;"  class="my-5">$50</p>
                            <p style="text-align:right;font-family:Roboto;color:#726659;font-weight:500;" >$500</p>
                            <input type="range" class="my-5" v-on:change="calculateSaving()" id="priceElentRange" min="0" max="500" step="50" v-model="valorSeleccionado">
                            <p style="text-align: center;font-family:Roboto;color:#726659;font-weight:700;" class="text-2xl">${{valorSeleccionado}}.00</p>
                        </div>

                        <div>
                            <p style="margin-top:30px; text-align:left;">Años</p>
                            <p style="margin-bottom:-25px;text-align:left;font-family:Roboto;color:#726659;font-weight:500;" class="my-5">1 año</p>
                            <p style="text-align:right;font-family:Roboto;color:#726659;font-weight:500;">60 años</p>
                            <input type="range" v-on:change="calculateSaving()" id="monthElentRange" min="0" max="60" v-model="anioSelecionado" class="my-5">
                            <p style="text-align: center; margin-bottom:25px;font-family:Roboto;color:#726659;font-weight:700;" class="text-2xl">{{anioSelecionado}} años</p>
                        </div>
                    </div>
                        
                    <div class="w-full mt-10 md:mt-0 md:w-3/4 h-full shadow-sm rounded-md border" style="background-color: #821740;font-family:Roboto; margin-bottom:55px;">
                        <center><p style="color:#ffffff" class="mt-10 text-2xl">Ahorro quincenal</p></center>
                        <center><p style="font-weight: bold;color:#ffffff!important" class="text-3xl">${{valorSeleccionado}}.00</p></center>
                        <center><p style="color:#ffffff" class="my-5 text-2xl">Ahorro total</p></center>
                        <center><p style="font-weight: bold; margin-top:-5px;color:#ffffff;" class="text-3xl">{{saldo == 0 ? "$0.00" : saldo}}</p></center>
                        <center><p style="color:#ffffff;font-weight:300;" class="my-5">Datos informativos sujetos a<br>validación y cierre de contratación</p></center>
                        <center><a href="/registro"><input type="button" value="Comienza tu ahorro" id="button_comenzar_ahorro" class="w-3/4 text-white" ></a></center><br>
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="flex-1 bg-gray-0 m-1" style="padding:10px 60px 10px 60px;">
            <div class="row">
                <table class="table table-striped">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>date</th>
                        <th>factor</th>
                        <th>monthlyInterest</th>
                        <th>accumulateSaving</th>
                        <th>accumulate</th>
                        <th>totalSaving</th>
                        <th>daysOnMonth</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(element,i) in proyection" :key="i">
                            <th scope="row"></th>
                            <th>{{element.date}}</th>
                            <td>{{element.factor}}</td>
                            <td>{{element.monthlyInterest}}</td>
                            <td>{{element.accumulateSaving}}</td>
                            <td>{{element.accumulate}}</td>
                            <td>{{element.totalSaving}}</td>
                            <td>{{element.daysOnMonth}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
</template>

<script>
import SavingsYieldCalculator from "@/classes/SavingsYieldCalculator";
export default {
  name: 'SimuladorAhorro',
  data() {
        return {
            anioSelecionado: 0,
            valorSeleccionado: 0,
            saldo: 0,
            factor: 0,//3.5,
            cost_by_saving_module: 50,
            SavingsYieldCalculatorObject:null,
            proyection:[]
        };
    },
    beforeMount() {
    },
    mounted() {
    },
    
    methods: {
        async calculateSaving(){
            if(this.anioSelecionado == 0 || this.valorSeleccionado == 0){
                this.saldo = 0
                return;
            }
            this.SavingsYieldCalculatorObject = new SavingsYieldCalculator();
            this.SavingsYieldCalculatorObject.execute(this.valorSeleccionado, this.anioSelecionado).then((response) => {
                this.proyection = response
                this.saldo = this.proyection[this.proyection.length-1].totalSaving.toFixed(2)
                this.saldo = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(this.saldo)
            })
        },
        // async (){
        //   if(this.anioSelecionado == 0 || this.valorSeleccionado == 0){
        //         this.saldo = 0
        //         return;
        //     }
        //     var saldoAnterior = 0
        //     for (var i = 1; i <= this.anioSelecionado; i++) {
        //         var interes = this.calculateInteres(saldoAnterior)
        //         this.saldo = this.calculateBanlance(saldoAnterior, interes)
        //         saldoAnterior = this.calculateBanlance(saldoAnterior, interes)
        //     }
        //     this.saldo = this.formatMoney(this.saldo)
        // },
        // calculateInteres(saldoAnterior){
        //     var valor = Number(saldoAnterior) + Number(this.valorSeleccionado)
        //     if(valor <= 100) { this.factor = 0 }
        //     if(valor > 100 && valor <= 5000) { this.factor = 0.5 }
        //     if(valor > 5000 && valor <= 20000) { this.factor = 2 }
        //     //if(valor > 20000 && valor <= 50000) { this.factor = 3 }
        //     if(valor > 20000) { this.factor = 3 }
        //     return (((Number(this.valorSeleccionado)*24)+Number(saldoAnterior))/(Number(this.cost_by_saving_module)))*Number(this.factor)
        // },
        // calculateBanlance(saldoAnterior, interes){
        //     return (((Number(this.valorSeleccionado)*24) + Number(interes)) + Number(saldoAnterior)).toFixed(2)
        // },
        // formatMoney(value){     
        //     const options2 = { style: 'currency', currency: 'MXN' };
        //     //const formatter = new Intl.NumberFormat('es-MX', options2);
        //     const formatter = new Intl.NumberFormat('en-US', options2);
        //     if(!value.includes(".")){
        //             resultado = value + ".00"
        //         }
        //     if(!value.includes(",")){
        //         var resultado = formatter.format(value)
        //     }
        //     resultado = resultado.replace('MX$','')
            
        //     return resultado
	    // }
    }   
}
</script>

<style scoped>
    #panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    #button_comenzar_ahorro{
        border-radius: 5px;
        background-color: #FEB72B;
        height: 50px;
        padding-top: 0%;
    }
input[type='range'] {
  display: block;
  /* width: 250px; */
  width: 100%;
}

input[type='range']:focus {
  outline: none;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
  background-color: #821740;
  width: 20px;
  height: 20px;
  border: 3px solid #821740;
  border-radius: 50%;
  margin-top: -9px;
}

input[type=range]::-moz-range-thumb {
  background-color: #821740;
  width: 15px;
  height: 15px;
  border: 3px solid #821740;
  border-radius: 50%;
}

input[type=range]::-ms-thumb {
  background-color: #821740;
  width: 20px;
  height: 20px;
  border: 3px solid #821740;
  border-radius: 50%;
}

input[type=range]::-webkit-slider-runnable-track {
  background-color: #821740;
  height: 3px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  outline: none;
}

input[type=range]::-moz-range-track {
  background-color: #821740;
  height: 3px;
}

input[type=range]::-ms-track {
  background-color: #821740;
  height: 3px;
}

input[type=range]::-ms-fill-lower {
  background-color: HotPink
}

input[type=range]::-ms-fill-upper {
  background-color: black;
} 

</style>