export default class SavingsYield {
    
    constructor(){
    }

    getCalculateNumberAccordingToTerm(years, period){
        switch (period) {
            case "meses":
                return SavingsYield.calculateMonths(years)
            default:
                throw "No se encontro un periodo valido"
        }
    }

    getAverageAccordingToTerm(accumulateSaving, value, period){
        switch (period) {
            case "meses":
                return SavingsYield.getAverageOfTheMonth(accumulateSaving, value)
            default:
                throw "No se encontro un periodo valido"
        }
    }

    getAccumulatedSavingsAccordingToTerm(iSfisrtMonth, amount, daysOnMonth, saving, period){
        switch (period) {
            case "meses":
                return SavingsYield.getAccumulatedSavingsMonth(iSfisrtMonth, amount, daysOnMonth, saving)
            default:
                throw "No se encontro un periodo valido"
        }
    }

    getFactor(factorList, averageSavingsOfTheMonth){
        for (let i = 0; i < factorList.length; i++) {
            if (factorList[i].factor_type == "factor de utilidad") {
                if(averageSavingsOfTheMonth >= factorList[i].min && averageSavingsOfTheMonth <= factorList[i].max){
                    return factorList[i].factor
                }
            }
        }
    }

    getAnnualizedFactor(factorList){
        for (let i = 0; i < factorList.length; i++) {
            if (factorList[i].factor_type == "factor de anualidad") {
                return factorList[i].factor
            }
        }
        throw "No se encontro factor de anualidad"
    }



    //----metodos privados---

    static calculateMonths(years) {
        return years*12
    }

    static getAverageOfTheMonth(accumulateSaving, daysOfMonth) {
        return accumulateSaving / daysOfMonth
    }

    static getAccumulatedSavingsMonth(iSfisrtMonth, amount, daysOnMonth, saving) {
        if(iSfisrtMonth){
            return ((daysOnMonth - 17) * Number(amount)) + (Number(amount)*2)
        }else{
            var savingQ1 =+ 14*(saving+Number(amount))
            var savingQ2 =+ (daysOnMonth - 15) * (saving + (Number(amount)*2))
            return savingQ1 + savingQ2
        }
    }
}